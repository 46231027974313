import * as React from 'react'
import DefaultLayout from "../layouts/default";
import ReactMarkdown from "react-markdown";
import {graphql} from "gatsby";
import rehypeRaw from 'rehype-raw';
import {getDefaultTitle} from "../logic/seo";
import {Helmet} from "react-helmet";

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
    public render() {http://localhost:1337/admin/plugins/content-manager/singleType/application::datenschutz.datenschutz
        return (
            <div>
                <Helmet>
                    <title>{getDefaultTitle("Datenschutz")}</title>
                    <meta name="description" content={this.props.data.strapiStaticPages.metaDescription}/>

                </Helmet>
                <DefaultLayout>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} >{this.props.data.strapiStaticPages.text}</ReactMarkdown>
                </DefaultLayout>
            </div>

        )
    }
}

export const query = graphql`
query datanschutz {
  strapiStaticPages(identifier: {eq: "Datenschutz"}) {
    id
    text
  }
}
`